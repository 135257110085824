var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app container",attrs:{"id":"app"}},[_c('navbar',{attrs:{"title":"<a href=\"/\"><span class=\"shorten-title-again\">Future </span><span class=\"shorten-title\">Plant</span> Hardiness Zones</a>","davey":"davey.png","arborday":"ArborDayFoundation.png"}}),_c('div',{staticClass:"px-lg-5 m-2"},[_c('GoogleMap',{attrs:{"image_prefix":"USA-Best-","pic_desc":"USA zones map","googleMap":{
        language: 'EN',
        region: 'USA',
        Options: {
          center: {'lat':39.828175, 'lng':-98.5795},
          mapOptions:{
            zoomSnap: 0.5,
          },
        }
      }}}),(!_vm.isEmbedded())?_c('hr'):_vm._e(),_c('Home')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }