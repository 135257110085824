<template>
  <div id="navbar">
    <b-navbar toggleable="sm" type="light" variant="light">
      <!-- XXX: does it need to be a property at all? -->
      <!-- FIXME: pass HTML through 'title' property. -->
      <!--
      <b-navbar-brand tag="h1" v-html="title" class="mb-0"></b-navbar-brand>
      -->
      <b-navbar-brand tag="h1" class="mb-0">
        <a href="/"><span class="shorten-title-again">Future </span><span class="shorten-title">Plant</span> Hardiness Zones</a>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <div>
            <b-navbar-brand target="_blank" href="https://www.davey.com/">
              <img :src="resolve_img_url(davey)" alt="Davey Tree">
            </b-navbar-brand>
          </div>

          <div>
            <b-navbar-brand target="_blank" href="https://www.arborday.org/">
              <img :src="resolve_img_url(arborday)" alt="Arbor Day Foundation">
            </b-navbar-brand>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
  export default {
    name: 'navbar',
    props:{
      title: String,
      davey: String,
      arborday: String
    },
    methods:{
      resolve_img_url: function (file_name) {
        let images = require.context('../assets/', false, /\.png$|\.jpg$/)
        return images('./' + file_name)
      },
    },
  }
</script>

<style scoped lang="scss">
  #navbar {
    .navbar {
      &.navbar-light {
        .navbar-brand {
          a {
            color: rgba(0, 0, 0, 0.9);
          }
        }
      }

      .navbar-brand {
        font-size: 1.5rem;
      }

      @media (max-width: 600px) {
        .navbar-brand {
          font-size: 1.15rem;
        }
      }

      @media (max-width: 400px) {
        padding: 6px 12px !important;

        .shorten-title {
          display: none;
        }

        .navbar-brand {
          margin-right: 0.5rem;
        }

        .navbar-toggler {
          padding: 0.2rem 0.5rem;
        }

        .navbar-toggler-icon {
          width: 20px;
        }
      }

      @media (max-width: 280px) {
        .navbar-toggler {
          width: 100%;
        }
      }

      @media (max-width: 225px) {
        .shorten-title-again {
          display: none;
        }
      }
    }
  }
</style>
