<template>
  <div id="table">
    <div class="title">
      Based on these models, most of your zip code may fall into these zones:
    </div>

    <b-table
      striped
      bordered
      stacked="sm"
      hover
      :items="items"
      :fields="fields"
      primary-key="zip"
    >
    </b-table>
  </div>
</template>


<script>
  const zone_map = {
    3: '2a',
    4: '2b',

    5: '3a',
    6: '3b',

    7: '4a',
    8: '4b',

    9: '5a',
    10: '5b',

    11: '6a',
    12: '6b',

    13: '7a',
    14: '7b',

    15: '8a',
    16: '8b',

    17: '9a',
    18: '9b',

    19: '10a',
    20: '10b',

    21: '11a',
  }


  export default {
    name: 'PivotTable',

    props: {
      locationData: Array,
      locationZip: String,
    },

    data() {
      return {
        mappedLocationData: null,

        fields: [
          {
            key: 'scenario',
            class: 'text-sm-right',
          },
          {
            key: '09_Maj',
            label: '1980 - 2009',
          },
          {
            key: '39_Maj',
            label: '2010 - 2039',
          },
          {
            key: '69_Maj',
            label: '2040 - 2069',
          },
          {
            key: '99_Maj',
            label: '2070 - 2099'
          },
        ],

        items: this.mapHardinessZones(this.locationData)
      }
    },

    methods:{
      mapHardinessZones: function (data) {
        let result = []

        for (const row of data) {
          let modified_row = row

          for (const [key, value] of Object.entries(modified_row)) {
            if (!isNaN(value)) {
              modified_row[key] = zone_map[value]
            }
          }

          result.push(modified_row)
        }

        return result
      },
    },

  }
</script>

<style lang="scss">
  #table {

    .title {
      color: #337ab7;
      display: block;
      text-align: start;
    }

    .table {
      font-weight: bold;
      text-transform: capitalize;

      thead {
        background-color: #4371c3;
        color: white;
      }
      tbody > tr {
        &:nth-child(1) {
          background-color: #cdd3e9;
        }
        &:nth-child(2) {
          background-color: #e7e9f4;
        }
      }

      &.table-bordered {
        thead {
          border-bottom: 4px solid white;

          > tr > th {
            border: 2px solid white;
          }
        }

        tbody > tr > td {
          border: 2px solid white;
        }
      }
    }
  }
</style>
