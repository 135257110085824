<template>

  <div role="tablist">

    <b-card no-body class="mb-1">
      <div v-for="(item, i) in fields" :key="i" :set="collapse_id = 'collapse-' + i">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button class="d-flex" block href="#" v-b-toggle=collapse_id variant="light">
            <span class="flex-fill mr-3 mr-md-auto">
              {{ item.header }}
            </span>
            <b-icon-chevron-compact-down scale="4" class="align-self-center p-1 my-1 ml-2 mr-1"></b-icon-chevron-compact-down>
          </b-button>
        </b-card-header>

        <b-collapse :id=collapse_id :accordion=collapse_id role="tabpanel">
          <b-card-body>
            <div v-for="p in item.content" :key="p">
              <p><b-card-text v-html="p"></b-card-text></p>
            </div>
          </b-card-body>
          </b-collapse>
        </div>
      </b-card>
  </div>
</template>

<script>
  export default {
    name: 'Accordion',

    props: {
    },

    data() {
      return {
        fields: [
          {
            header: 'What are Plant Hardiness Zones?',
            content:[
              `Plant hardiness is a measure of its tolerance to cold. Plants that can withstand greater cold during winter without being damaged are said to have greater hardiness. For example, paper birch and white spruce trees that survive the very cold winters of Alaska are much hardier than palm trees that can’t tolerate freezing temperatures.`,
              `Plant Hardiness Zones divide the United States and Canada into 11 areas based on 10°F differences in the average annual minimum temperature (the United States falls within Zones 2 through 10). For example, the lowest average temperature for Zone 2 is -50 to -40°F, while the lowest average temperature for zone 10 is +30 to +40°F. Each Hardiness Zone is further subdivided into zone a and b based on increments of 5°F. For example, Zones 2a and 2b range from -50 to -45°F and -45 to -40°F, respectively.Plant Hardiness Zones divide the United States and Canada into 11 areas based on 10°F differences in the average annual minimum temperature (the United States falls within Zones 2 through 10). For example, the lowest average temperature for Zone 2 is -50 to -40°F, while the lowest average temperature for zone 10 is +30 to +40°F. Each Hardiness Zone is further subdivided into zone a and b based on increments of 5°F. For example, Zones 2a and 2b range from -50 to -45°F and -45 to -40°F, respectively.`,
              `Most trees, shrubs, and perennials have been evaluated for their winter hardiness. If a range of zones, for example, zones 4-9, is indicated, the plant is known to be hardy in zones 4, 5, 6, 7, 8, and 9. Suitable hardiness means a plant can be expected to grow in the zone’s temperature extremes, as determined by the lowest average annual temperature. Maps of Plant Hardiness Zones are useful guides to aid in the selection of trees for planting in your community
              (<a target="_blank" href="https://www.arborday.org/media/zones.cfm">see more</a>).
              Keep in mind that local variation in precipitation, soil, wind, and other conditions also affect the viability of individual plants. You may want to ask a
              <a target="_blank" href="https://www.arborday.org/trees/health/arborist.cfm">local professional arborist</a>
              or nursery about which trees to plant in your locality.`,
            ]
          },
          {
            header: 'Climate Warming will Change Plant Hardiness Zones',
            content:[
              `As greenhouse gas emissions continue to warm the climate, the palette of trees that will thrive in a particular area will also change. In fact, the climate is warming fast enough to change the hardiness zone within the lifespan of trees planted today. This interactive website generates maps and projections of how climate warming will change Plant Hardiness Zones by the middle and end of this century for specific zip codes throughout the United States. These projections are intended to increase awareness about the magnitude of climate change at specific locations, and to inform decisions about what trees to plant for the changing climate.`,
            ]
          },
          {
            header: 'How are Changes in Plant Hardiness Zones Projected?',
            content:[
              `Earth’s climate is projected to warm by an additional 3-11°F by the end of this century, with the largest source of uncertainty being future patterns of greenhouse gas emissions. Projections of future climate outcomes are based on different emissions scenarios, called “Representative Concentration Pathways (RCPs).”  More information on RCPs can be found
              <a target="_blank" href="https://skepticalscience.com/docs/RCP_Guide.pdf">here</a>.`,
              `This website projects changes in Plant Hardiness Zones based on both low a (RCP4.5) and a high (RCP8.5) emission scenario. Currently, global patterns of greenhouse gas emissions correspond most closely with the high emission scenario, while the lower emission scenario will require significant mitigation measures yet to be implemented.`,
            ]
          },
        ],
      }
    },
  }
</script>

<style scoped lang="scss">
.header {
  font-weight: bold;
}
</style>
