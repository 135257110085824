import './registerServiceWorker'

import Vue from 'vue'
Vue.config.productionTip = false

import VueLayers from 'vuelayers'
Vue.use(VueLayers)

import VueRouter from 'vue-router';
Vue.use(VueRouter);

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {config: {id: "G-86XMF8G71E"}});

import {
  BootstrapVue,
  IconsPlugin,
} from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import App from './App.vue'
import './site-wide.scss'


new Vue({
  router: new VueRouter({
    mode: 'history',
  }),
  render: h => h(App),
}).$mount('#app')
