<template>
  <div class="app container" id="app">
    <!-- FIXME: pass HTML through 'title' property. -->
    <navbar
      title='<a href="/"><span class="shorten-title-again">Future </span><span class="shorten-title">Plant</span> Hardiness Zones</a>'
      davey="davey.png"
      arborday="ArborDayFoundation.png"
    />

    <div class="px-lg-5 m-2">
      <GoogleMap
        image_prefix="USA-Best-"
        pic_desc='USA zones map'
        :googleMap="{
          language: 'EN',
          region: 'USA',
          Options: {
            center: {'lat':39.828175, 'lng':-98.5795},
            mapOptions:{
              zoomSnap: 0.5,
            },
          }
        }"
      />

      <hr v-if="!isEmbedded()" />

      <Home />
    </div>
  </div>
</template>

<script>
  import Navbar from './components/Navbar.vue'
  import GoogleMap from './components/Map.vue'
  import Home from './components/Home.vue'
  import {flagEnabled} from './utility.mjs'

  export default {
    name: 'App',
    components: {
      Navbar,
      GoogleMap,
      Home,
    },
    methods:{
      isEmbedded: function () {
        let embed = this.$route.query.embedded
        if (flagEnabled(embed)) {
          return true
        }
        embed = this.$route.query.embed
        return flagEnabled(embed)
      }
    },
  }
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;

    @media (max-width: 768px){
      &.container {
        min-width:100%;
        padding: 0;
      }
    }
  }
</style>
