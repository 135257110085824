<template>
  <div id="help" class="accordion">
    <accordion v-if="!isEmbedded()" />

    <p>
      <span class="header">Source of Data Used to Project Changes in Hardiness Zones:</span>
      The data used to generate these maps are sourced from this publication of the U.S. Forest Service:
      <a target="_blank" href="https://www.fs.usda.gov/research/treesearch/55870"
        >Mathews, S.N., L.R. Iverson, M.P. Peters, and A.M. Prasad. 2018.
        Assessing potential climate change pressures across the conterminous United
        States: mapping plant hardiness zones, heat zones, growing degree days, and
        cumulative drought severity throughout this century.</a>
    </p>
  </div>
</template>

<script>
  import Accordion from './Accordion.vue'
  import {flagEnabled} from '../utility.mjs'

  export default {
    name: 'home',
    props: {
    },
    components:{
      Accordion,
    },
    methods:{
      isEmbedded: function () {
        let embed = this.$route.query.embedded
        if (flagEnabled(embed)) {
          return true
        }
        embed = this.$route.query.embed
        return flagEnabled(embed)
      }
    },
  }
</script>

<style scoped lang="scss">
#help {
  text-align: start;

  .header {
    font-weight: bold;
  }

  p {
    padding-top: 1em;
  }
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}
</style>
