// Returns true for true, "true", "yes", "y", "on", "1", 1, as well as
// strings and floats between 1 and 2.
function flagEnabled(item)
{
  if (typeof item === 'undefined' || item === null) {
    return false
  }

  try {
    let item1 = item.trim().toLowerCase()
    if (
      item1 === 'true'
      || item1 === 'yes'
      || item1 === 'y'
      || item1 === 'on'
    ) {
      return true
    }
  } catch (err) {
    if (!(err instanceof TypeError)) { // String.trim, String.toLowerCase
      throw err
    }
  }

  let item2 = parseInt(parseFloat(item))
  if (item2 === 1) {
    return true
  }

  try {
    let item3 = JSON.parse(item)
    if (typeof item3 === 'boolean' && item3) {
      return true
    }
  } catch (err) {
    if (!(err instanceof SyntaxError)) { // JSON.parse
      throw err
    }
  }

  return false
}


export {flagEnabled}

