<template>
  <div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      fade
      variant="warning"
      @dismiss-count-down="countDownChanged"
    >
      {{geoStatus}}
    </b-alert>

    <div class="map-container d-flex flex-row justify-content-center my-2">
      <b-img
        class="legend"
        :class="{ hidden: legendIsHidden }"
        :src="resolve_img_url('legend.png')"
      >
      </b-img>

      <div class="map">
        <vl-map
          ref="map"
          :load-tiles-while-animating=true
          :load-tiles-while-interacting=true
          :rotation.sync="rotation"
          @mounted="onMapMounted"
          @postcompose="onMapPostCompose"
        >
          <vl-view
            :zoom="zoom"
            :zoomFactor="zoomFactor"
            :center="center"
          />

          <vl-overlay
            v-if="overlayCoordinate.length"
            id="zone-name-overlay"
            :position="overlayCoordinate"
            positioning="bottom-left">
            <template>
              <div class="overlay-content">
                {{ zoneName }}
              </div>
            </template>
          </vl-overlay>

          <vl-layer-vector>
            <vl-feature>
              <vl-geom-point
                v-if="overlayCoordinate.length"

                :coordinates="overlayCoordinate"
              ></vl-geom-point>

              <vl-style-box>
                <vl-style-circle :radius="2">
                  <vl-style-fill color="transparent"></vl-style-fill>
                  <vl-style-stroke :width=1.3 color="black"></vl-style-stroke>
                </vl-style-circle>
              </vl-style-box>
            </vl-feature>
          </vl-layer-vector>

          <vl-feature v-if="showPositionIcon">
            <vl-geom-point :coordinates="center"></vl-geom-point>
            <vl-style-box>
              <vl-style-icon
                :src="resolve_img_url('marker.png')"
                :scale="positionIconSize"
                :anchor="positionIconAnchor">
              </vl-style-icon>
            </vl-style-box>
          </vl-feature>

          <vl-geoloc
            v-if="trackMe"
            @update:position="onUpdatePosition"
            :trackingOptions="trackingOptions"
          >
            <template slot-scope="geolocation">
              <vl-feature v-if="geolocation.position" id="position-feature">
                <vl-geom-point :coordinates="geolocation.position"></vl-geom-point>
                <vl-style-box>
                  <vl-style-icon
                    :src="resolve_img_url('marker.png')"
                    :scale="positionIconSize"
                    :anchor="positionIconAnchor">
                  </vl-style-icon>

                </vl-style-box>
              </vl-feature>
            </template>
          </vl-geoloc>

          <vl-layer-image
            id="image-layer"
            ref="imageLayer"
            :opacity="imgOpacity"
            :z-index=99999
            crossOrigin='anonymous'
          >
            <vl-source-image-static
              v-if="currentImg"
              :url="currentImg.path"
              :size="imgSize"
              :extent="imgExtent"
              visible="false"
            >
            </vl-source-image-static>
          </vl-layer-image>

          <vl-layer-tile>
            <vl-source-xyz
              :attributions="gAttributions"
              :url="gURL"
              :isBaseLayer=true
              crossOrigin='anonymous'
            >
            </vl-source-xyz>
          </vl-layer-tile>
        </vl-map>
      </div>
    </div>

    <div class="map-controls">
      <!--          <p>{{currentIndex}}: {{currentImg.name}} - {{currentImg.path}}</p>-->
      <b-form id="scenarios" class="d-flex flex-row justify-content-center m-2" inline>
        <b-form-group
          id="scenarios-label"
          label="Estimated Scenario:"
          class="m-0"
        ></b-form-group>
        <b-form-radio-group
          aria-labelledby="scenarios-label"
          v-model="scenario_selected"
          :options="options"
          class="ml-2"
          value-field="item"
          text-field="name"
          disabled-field="notEnabled"
          inline
          v-on:input="update_img_urls"
        ></b-form-radio-group>
      </b-form>

      <div class="d-flex flex-row justify-content-center m-2">
        <div class="d-inline-block">
          Transparency
          <range-slider
            class="slider"
            min="0.15"
            max="1"
            step="0.01"
            v-model="imgOpacity">
          </range-slider>
        </div>
      </div>

        <div class="controls d-flex flex-row justify-content-center m-2">
          <b-button-group
            horizontal
            size="md"
            class="years d-none d-sm-block"
          >
              <b-button
                v-for="(image, idx) in images"
                v-bind:key="image.name"
                @click="display_image(idx)"
                :pressed="currentImg === image ? true : false"
                variant="primary"
                :style="currentImg === image ? {} : { backgroundColor: '#fff', color: '#2c3e50'}"
              >
                {{image.name}}
              </b-button>
          </b-button-group>

          <b-button-group
            vertical
            size="md"
            class="years d-block d-sm-none"
          >
            <b-button
              v-for="(image, idx) in images"
              v-bind:key="image.name"
              @click="display_image(idx)"
              :pressed="currentImg === image ? true : false"
              variant="primary"
              :style="currentImg === image ? {} : { backgroundColor: '#fff', color: '#2c3e50'}"
            >
              {{image.name}}
            </b-button>
          </b-button-group>
        </div>

      <div class="controls d-flex flex-row justify-content-center m-2">
        <b-button-group>
          <b-button
            class="prev"
            size="lg"
            @click="prev"
            variant="outline-secondary"
          >
            <b-icon-chevron-compact-left
              class="mt-1 mr-1" scale="1.5"
            >
            </b-icon-chevron-compact-left>
            <span class="d-none d-sm-inline">Previous</span>
          </b-button>

          <b-button
            ref="playPauseButton"
            class="toggle-play-pause"
            size="lg"
            :pressed.sync="playPressed"
            @click="toggleSlide"
            variant="outline-primary"
          >
            <span :class="playPressed ? 'hidden' : ''">
              <b-icon-play-fill class="mt-1" scale="2"></b-icon-play-fill>
            </span>
            <span :class="playPressed ? '' : 'hidden'">
              <b-icon-pause class="mt-1" scale="1.75"></b-icon-pause>
            </span>
          </b-button>

          <b-button
            class="next"
            size="lg"
            @click="next"
            variant="outline-secondary"
          >
            <span class="d-none d-sm-inline">Next</span>
            <b-icon-chevron-compact-right
              class="mt-1 ml-1" scale="1.5"
            >
            </b-icon-chevron-compact-right>
          </b-button>
        </b-button-group>
      </div>

      <div class="search m-2">
        <div class="title d-flex flex-row justify-content-center my-2 mx-sm-5 mx-md-0">
          Enter your five digit zip code to learn which hardiness zone your location will fall within:
        </div>

        <div class="d-flex flex-row justify-content-center my-2 p-0">
          <b-form @submit="get_img_by_zip" @reset="reset_map" inline>
            <span class="d-none d-sm-inline">Zip Code</span>
            <b-input
              id="zip-input"
              v-model="form.zip"
              class="mr-1 ml-2"
              type="number"
              min="0"
              max="99999"
              :placeholder="locationsLoadingMessage"
              :disabled="locationsReady == false"
            ></b-input>

            <div>
              <b-button type="submit" variant="primary">
                <b-icon-search></b-icon-search>
              </b-button>

              <b-button class="mx-1" type="reset" variant="primary">
                <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              </b-button>
            </div>
          </b-form>

        </div>
      </div>
    </div>

    <location-data-pivot-table
      v-if="locationData"
      :locationData="locationData"
      :key="locationZip"
    />
  </div>
</template>

<script>
  import RangeSlider from 'vue-range-slider'

  import {createProj, addProj} from 'vuelayers/lib/ol-ext/proj'

  import Button from 'ol-ext/control/Button'

  import {transform} from 'ol/proj'
  import {fromLonLat} from 'ol/proj'
  import {getCenter} from 'ol/extent'

  import PivotTable from './PivotTable.vue'
  const min_zone_val = 3
  const max_zone_val = 21

  import '@fortawesome/fontawesome-free'

  let size = [403, 255]
  let imageExtent = [
    -13894119.6043808571994305, 2868442.7942650755867362,
    -7464380.8556630248203874, 6936887.9082180466502905
  ]

  const hardiness_colors = {
    '170,0,255,255': '2a',
    '107,10,204,255': '2b',
    '73,13,255,255': '3a',
    '36,112,255,255': '3b',
    '28,138,255,255': '4a',
    '18,200,255,255': '4b',
    '8,251,255,255': '5a',
    '0,230,169,255': '5b',
    '42,255,0,255': '6a',
    '170,255,0,255': '6b',
    '209,255,115,255': '7a',
    '255,255,115,255': '7b',
    '255,238,0,255': '8a',
    '255,217,0,255': '8b',
    '255,187,0,255': '9a',
    '255,128,0,255': '9b',
    '255,42,0,255': '10a',
    '168,0,0,255': '10b',
    '115,0,0,255': '11a',
  }

  let customProj = createProj({
    code: 'hardiness-image-projection',
    units: 'pixels',
  })
  addProj(customProj)

  const getLocationsData = () => import('../assets/zip.json')
  let locationsData = []
  let locationsReady = false
  let locationsLoadingMessage = "Loading..." // keep short (#zip-input width).

  export default {
    name: "GoogleMap",

    components: {
      RangeSlider,
      'location-data-pivot-table': PivotTable,
    },
    props: {
      googleMap: Object,

      title: String,
      image_prefix: String,
      pic_desc: String,
    },

    data() {
      getLocationsData().then((data) => {
        locationsData = Object.values(data)
        locationsReady = true
        locationsLoadingMessage = "" // clear message.
      })

      return {
        imgOpacity: 0.65,

        legendIsHidden: true,

        trackMe: false,
        zoneLabelsOn: false,
        zoneLabelsControl: null,
        geoIcon: null,

        trackingOptions: {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0
        },
        positionIconSize: 0.2,
        positionIconAnchor: [0.5, 1],
        showPositionIcon: false,

        dismissSecs: 5,
        dismissCountDown: 0,
        geoStatus: '',
        showDismissibleAlert: false,
        geoBtnVisible: false,

        mousePosition: null,
        mapMove: null,
        mousePositionControl: null,
        overlayCoordinate: [0, 0],
        zoneName: '',

        zoom: 3,
        zoomFactor: 1.2,

        rotation: 0,
        projection: customProj,
        imgSize: size,

        center: transform(getCenter(imageExtent), customProj, 'EPSG:3857'),
        imgExtent: imageExtent,
        //===================================================================

        gURL: 'https://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
        gAttributions: '' +
          '<a href="https://www.google.at/permissions/geoguidelines/attr-guide.html">' +
          'Map data ©2015 Google' +
          '</a>',
        //===================================================================


        form: {
          zip: null
        },

        zipLocation: false,
        scenario_selected: 'Best',
        options: [
          {item: 'Best', name: 'Best'},
          {item: 'Worst', name: 'Worst'},
        ],

        get locationsData() { return locationsData },
        get locationsReady() { return locationsReady },
        get locationsLoadingMessage() { return locationsLoadingMessage },

        // TODO: refactor locationData or locationsData to get rid of
        //   their similarity.
        locationData: null,
        locationZip: null,

        images: [
          // Example: "USA-2040-2069.png",
        ],
        timer: null,
        delay: false,
        currentIndex: 0,
        playPressed: false,
      }
    },

    updated: function () {},

    mounted: function () {
      this.$refs.playPauseButton.click()
      this.fitExtent()

      this.get_img_urls(this.image_prefix)
    },

    methods: {
      hideZoneFeature() {
        this.overlayCoordinate = []
      },

      onMapPostCompose() {
        if (this.zoneLabelsOn){
          this.displayHardinessZoneValueForLayer('image-layer')
        }
      },

      onMapMounted() {
        let legend_button = new Button({
          className: 'ol-collapsed ol-legend',
          name: 'legend',
          title: 'Legend',
          handleClick: this.toggle_legend
        })

        let track_me_button = new Button({
          html: '<i class="fas fa-map-marker-alt" style="cursor: pointer;"></i><i class="fa-placeholder"></i>',
          className: 'ol-rotate track-me-button',
          name: 'track-me',
          title: 'Zoom in my location',
          handleClick: this.toggle_track_me
        })

        let zone_label_button = new Button({
          html: '<i class="fa fa-eye-dropper" style="cursor: pointer;"></i>',
          className: 'zone-label-button',
          name: 'zone-label',
          title: 'Zone labels on click',
          handleClick: this.toggle_zone_labels
        })

        this.$refs.map.$map.addControl(legend_button)
        this.$refs.map.$map.addControl(track_me_button)
        this.$refs.map.$map.addControl(zone_label_button)

        let self = this
        this.$refs.map.$map.on('movestart', function () {
          self.hideZoneFeature()
          self.mapMove = true
        })
        this.$refs.map.$map.on('moveend', function () {
          self.mapMove = false
          self.mousePosition = null
        })
      },

      displayHardinessZoneValueForLayer(layerName) {
        if (this.mousePosition === null || this.mapMove) {
          return
        }
        this.hideZoneFeature()

        this.$refs.map.$map.forEachLayerAtPixel(
          this.mousePosition,
          this.displayHardinessZoneValue,
          {
            layerFilter: function (layer) {
              return layer.get('id') === layerName;
            }
          }
        )
      },

      enableZoneNameOverlayOnMouseMove() {
        this.zoneLabelsOn = true
        this.toggle_map_control_style(this.zoneLabelsOn, this.zoneLabelsControl)
        this.$refs.map.$el.addEventListener('touchend', this.zoneNameOverlayOnMouseEvent)
        this.$refs.map.$el.addEventListener('click', this.zoneNameOverlayOnMouseEvent)
      },

      zoneNameOverlayOnMouseEvent(event) {
        const mousePosition = this.$refs.map.$map.getEventPixel(event)
        if (!isNaN(mousePosition[0])) {
          this.mousePosition = mousePosition
        }

        this.displayHardinessZoneValueForLayer('image-layer')
      },

      disableZoneNameOverlayOnMouseMove() {
        this.hideZoneFeature()

        this.zoneLabelsOn = false
        this.toggle_map_control_style(this.zoneLabelsOn, this.zoneLabelsControl)

        this.$refs.map.$el.removeEventListener('touchend', this.zoneNameOverlayOnMouseEvent)
        this.$refs.map.$el.removeEventListener('click', this.zoneNameOverlayOnMouseEvent)
      },

      displayHardinessZoneValue(layer, color) {
        for (const [color_array_str, zone_name] of Object.entries(hardiness_colors)){
          if (color_array_str === color.toString()){
            this.overlayCoordinate = this.$refs.map.$map.getCoordinateFromPixel(this.mousePosition)
            this.zoneName = zone_name

            break
          }
        }

        return true
      },

      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },

      showAlert(message) {
        this.dismissCountDown = this.dismissSecs
        this.geoStatus = message
      },

      fitExtent() {
        this.$refs.map.$mountPromise.then(() => {
          this.$refs.imageLayer.$mountPromise.then(() => {
            this.$refs.map.$view
              .fit(
                this.imgExtent,
                this.$refs.map.$map.getSize()
              )

            this.zoom = this.$refs.map.$view.getZoom()
          })
        })
      },

      zoomIn() {
        this.zoom += 6
      },

      onUpdatePosition(coordinate) {
        this.center = coordinate
        this.showPositionIcon = false
        this.zoomIn()

        this.toggle_map_control_style(this.trackMe, this.geoIcon)
        this.remove_busy_icon(this.geoIcon)
      },

      geoPermissionAllow: function () {
        let self = this
        return navigator.permissions
          .query({name: 'geolocation'})
          .then(function (result) {
            if (result.state === 'denied') {
              self.showAlert('Permission ' + result.state +
                '. Please enable geo tracking for this site in your browser.')
              return false
            } else {
              return true
            }
          })
      },

      toggle_map_control_style: function (isEnabled, control) {
        if (typeof control === 'undefined' || control === null) {
          return
        }

        if (isEnabled) {
          control.classList.add('control-active')
        } else {
          control.classList.remove('control-active')
        }
      },

      toggle_track_me_icon: function (trueFalse) {
        return trueFalse
          ? 'fas fa-tint-slash fa-rotate-180'
          : 'fas fa-map-marker-alt'
      },

      toggle_slash_icon: function (trueFalse) {
        return trueFalse
          ? 'fas fa-slash fa-stack-1x'
          : ''
      },

      toggle_legend_slash_icon: function (trueFalse) {
        return trueFalse
          ? 'fas fa-slash'
          : ''
      },

      set_busy_icon: function (geoIcon) {
        geoIcon.style.cursor = 'wait'
        document.body.style.cursor = 'wait'
      },

      remove_busy_icon: function (geoIcon) {
        geoIcon.style.cursor = 'pointer'
        document.body.style.cursor = ''
      },

      toggle_track_me: function (e) {
        const locate = !this.trackMe

        this.geoIcon = e.currentTarget
        this.set_busy_icon(this.geoIcon)

        this.toggle_map_control_style(locate, e.currentTarget)

        if (locate) {
          if (!navigator.geolocation) {
            this.showAlert('Geolocation is not supported by your browser')
            this.remove_busy_icon(this.geoIcon)

            return
          }

          this.geoPermissionAllow()
            .then((status) => {
              this.trackMe = status

              if (!status){
                this.toggle_map_control_style(this.trackMe, e.currentTarget)
                this.remove_busy_icon(this.geoIcon)
              }
            })
        } else {
          this.trackMe = !this.trackMe

          this.toggle_map_control_style(this.trackMe, e.currentTarget)
          this.remove_busy_icon(this.geoIcon)
        }
      },

      toggle_zone_labels: function (e) {
        this.zoneLabelsOn = !this.zoneLabelsOn

        this.zoneLabelsControl = e.currentTarget
        this.toggle_map_control_style(this.zoneLabelsOn, e.currentTarget)

        if (this.zoneLabelsOn){
          this.enableZoneNameOverlayOnMouseMove()
        } else {
          this.disableZoneNameOverlayOnMouseMove()
        }
      },

      toggle_legend: function (e) {
        this.legendIsHidden = !this.legendIsHidden

        // Hidden vs On makes this one use the reverse status.
        this.toggle_map_control_style(!this.legendIsHidden, e.currentTarget)

        this.$refs.map.$createPromise.then(() => {
          this.$refs.map.$map.updateSize()
        })
      },


      getLocationDataByCode: function(code){
        return this.locationsData.find(
          row => parseInt(row.ZIP_CODE) === parseInt(code))
      },

      validateLocation: function(data){
        let is_valid = true

        for (const row of data) {
          for (const key_value of Object.entries(row)) {
            const val = key_value[1]
            if (
              !isNaN(val) &&
              (!Number.isInteger(val) || !(val >= min_zone_val && val <= max_zone_val))
            ) {
              is_valid = false
              break
            }
          }

          if (!is_valid) {
            return is_valid
          }
        }

        return is_valid
      },

      geocodeAddress: function(){
        this.locationData = null

        const location = this.getLocationDataByCode(this.form.zip)
        if (location) {
          const formatted_location_data = [
            {
              'scenario': 'best',
              '09_Maj': location.R45_09_Maj,
              '39_Maj': location.R45_39_Maj,
              '69_Maj': location.R45_69_Maj,
              '99_Maj': location.R45_99_Maj,
            },
            {
              'scenario': 'worst',
              '09_Maj': location.R85_09_Maj,
              '39_Maj': location.R85_39_Maj,
              '69_Maj': location.R85_69_Maj,
              '99_Maj': location.R85_99_Maj,
            }
          ]

          if (this.validateLocation(formatted_location_data)){
            this.locationData = formatted_location_data
            this.locationZip = this.form.zip

            this.center = fromLonLat([location.lng, location.lat])
            this.zoomIn()
          } else {
            alert('No data for this location. Please try nearby zip codes')
          }
        } else {
          this.reset_map()
          alert('US location not found')
        }
      },

      get_img_by_zip: function (e) {
        e.preventDefault()

        const zip = parseInt(this.form.zip)
        if (isNaN(zip) || this.form.zip.length !== 5){
          alert('Please use five digit US ZIP codes only.')

          return
        }

        this.geocodeAddress()

        this.showPositionIcon = true
      },

      reset_map: function () {
        this.showPositionIcon = false

        this.stopSlide()
        this.currentIndex = 0
        this.playPressed = false

        this.get_img_urls('USA-Best-')
        this.zipLocation = false
        this.scenario_selected = 'Best'
        this.form.zip = null
        this.locationData = null
        this.center = transform(getCenter(imageExtent), customProj, 'EPSG:3857')
        this.fitExtent()
      },

      resolve_img_url: function (path) {
        let images = require.context('../assets/', false, /\.png$|\.jpg$/)
        return images('./' + path)
      },

      display_image: function (image_index) {
        if (this.playPressed) {
          this.$refs.playPauseButton.click()
        }

        this.currentIndex = image_index
      },

      update_img_urls: function () {
        let tmp = this.image_prefix
        let location = tmp.split('-')[0]
        let new_location = ''.concat(location, '-', this.scenario_selected, '-')
        this.get_img_urls(new_location)
      },

      get_img_urls: function (path) {
        let images = require.context('../assets/', false, /\.png$|\.jpg$/i)
        let result = []
        images.keys().forEach(function (key) {
          if (key.includes(path)) {
            let r = key.split(path)[1]
            let name = r.replace('.png', '')
            name = name.replace('.jpg', '')
            name = name.replace(' - ', '\u2013') // &ndash; the "en dash".

            result.push({"name": name, "path": images(key)})
          }
        })

        this.images = result
      },

      stopSlide: function () {
        clearTimeout(this.timer)
        this.delay = false
      },

      toggleSlide: function () {
        this.stopSlide()

        if (!this.playPressed) {
          return
        }

        let self = this
        let $next = this.next

        this.timer = setTimeout(
          function tick() {
            $next()

            if (
              [0, self.images.length - 1].includes(self.currentIndex)
              && self.delay
            ) {
                self.delay = 2000
            } else {
              self.delay = 1000
            }

            self.timer = setTimeout(tick, self.delay)
          },
          self.delay,
        )
      },

      next: function () {
        this.currentIndex = (this.currentIndex + 1) % this.images.length
      },

      prev: function () {
        if (this.currentIndex === 0) {
          this.currentIndex = this.images.length - 1
        } else {
          this.currentIndex = (this.currentIndex - 1) % this.images.length
        }
      }
    },

    computed: {
      currentImg: function () {
        return this.images[Math.abs(this.currentIndex) % this.images.length]
      }
    },
  }

</script>

<style lang="scss">
  @import "~vue-range-slider/dist/vue-range-slider.css";
  @import "~ol-ext/dist/ol-ext.css";
  @import "~vuelayers/lib/style.css";
  @import "~@fortawesome/fontawesome-free/css/all.css";

  .fade-enter-active, .fade-leave-active {
    transition: all 0.9s ease;
  }

  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  .map-container {
    height: 50vh;

    --parentHeight: 50vh;

    @media (max-width: 900px) and (orientation: landscape) {
      height: 70vh;
    }

    /* 575 is max xs. */
    @media (max-width: 575px) and (orientation: portrait) {
      height: 35vh;
    }

    .map {
      /*needed in order to center map correctly*/
      text-align: start;

      height: 100%;
      width: calc(var(--parentHeight) + 30%);

      .track-me-button {
        bottom: 3em;
        left: 0.5em;
        top: auto;
        right: auto;
      }

      .zone-label-button {
        bottom: 5.5em;
        left: 0.5em;
        top: auto;
        right: auto;

        .fas.fa-slash.fa-stack-1x{
          margin-top: 5px;
        }
      }

      .overlay-content {
        font-weight: bold;
        background-color: white;
        border-radius: 4px;
        padding-right: 4px;
        padding-left: 4px;
        margin-bottom: 2px;
      }

      button.control-active {
        background-color: rgba(21, 135, 7, 0.5);
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.4);
        color: #222;

        &:hover {
          background-color: rgba(21, 135, 7, 0.7);
        }
      }
    }

    .legend {
      height: 100%;
      width: auto;
    }
  }

  .img {
    /*TODO: correct aspect ratios*/
    /*width: 100%;*/

    img {
      max-height: 100%;

      width: 100%;
    }
  }

  .range-slider {
    &.slider {
      vertical-align: text-bottom;
    }
  }

  .controls { // needs this extra specificity to override btn.
    .prev, .next {
      color: #2c3e50; // copied from #app color.

      &:hover,
      &:visited,
      &:active, .active {
        color: #2c3e50; // copied from #app color.
      }

      &:hover {
        background-color: transparent;
      }
    }

    .toggle-play-pause {
      background-color: transparent !important;
      color: #007bff !important; // copied from btn.link color.

      &:active, .active {
        background-color: transparent;
      }
    }
  }

  .hidden {
    display: none;
  }

  #zip-input {
    width: 7rem;
  }

  #scenarios {
    #scenarios-label {
      font-weight: bold;

      legend {
        padding: 0;
      }
    }
  }

  .search {
    text-align: start;

    input{
      width: auto;
    }

    .title {
      color: #337ab7;
      display: block;
    }

    .search-message {
      #location-details {
        .zone-details {
          white-space: pre;

          &:not(:last-child) {
            border-right: black 2px solid;
          }
        }
      }

      .sub-title {
        font-weight: bold;
      }

      display: block;
    }
  }
</style>
